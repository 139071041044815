import React, { useState } from "react"
import Layout from "../../components/Layout"
import Image from "../../components/Image"
import SectionBanner from "../../components/SectionBanner"
import Section from "../../components/Section"
import SectionOverview from "../../components/SectionOverview"
import Seo from "../../components/Seo"
import { useStaticQuery, graphql, Link } from "gatsby"


function CaseStudies() {
  const data = useStaticQuery(graphql`
    query SingleCase {
      allCaseStudiesJson(sort: {fields: order, order: DESC}) {
        nodes {
          jsonId
          overlayDescription
          primaryColor
          details {
            client
            client_abrev
          }
          title
          landingImage
          landingImageAlt
        }
      }
    }
  `)

  const caseStudyData = data.allCaseStudiesJson.nodes;

  function CaseStudyItem(props) {
    const [isHovered, setIsHovered] = useState(false)

    const handleMouseOver = () => {
      setIsHovered(true);
    };
    
    const handleMouseOut = () => {
      setIsHovered(false);
    };

    const latestClass = props.id !== 0 ? "child" : "parent";
    const overlayClass = "case-study-list__item-overlay " + latestClass;
    const overlayStyle = isHovered ? `10px solid ${props.caseData.primaryColor}` : "";

    return (
      <li
        className={`case-study-list__item ${latestClass}`}
        key={props.id}
        style={props.id === 0 ? { width: "100%" } : null}
      >
        <Link
          className="case-study-item__link case-studies button--inverted"
          to={`/our-work/${props.caseData.jsonId}/`}
        >
          <div className="case-study-list__item-content" onMouseOver={handleMouseOver} onFocus={handleMouseOver} onMouseOut={handleMouseOut} onBlur={handleMouseOut}>
            <div>
              <div className={overlayClass} style={{ border: overlayStyle }}>
                </div>
              </div>
              <Image
                className="case-study-list__item-image"
                src={`case-studies/${props.caseData.landingImage}`}
                alt={props.caseData.landingImageAlt}
              />
            </div>
            <div>
              <h3 className="case-study-list__item-client">
                  {props.caseData.overlayDescription}
              </h3>
            </div>
            <div class="case-study-list__item-title">
                {props.caseData.details.client_abrev}
              </div>

        </Link>
      </li>
    )
  }

  function CaseStudyList(props) {
    return (
      <ul className="case-study-list">
        {props.data.map((c, i) => {
          return <CaseStudyItem caseData={c} id={i} key={`case-study-${i}`} />
        })}
      </ul>
    )
  }

  return (
    <Section spacing={0} id={"case-studies"}>
      <CaseStudyList data={caseStudyData} />
    </Section>
    )
  }

  function HeadingIntro(props) {
    return (
      <Section
        id="case-studies__what-we-do"
        constrain
        indent
        spacing={40}
      >
        <SectionOverview
          align="center"
          divider
        >
          <h2>{props.title}</h2>
        </SectionOverview>
        <p>
        At Bixal, we lead human-centered digital transformation and global 
        solutions that drive equity, sustainability and social good. We leverage 
        technology, communications, data and human-centered design to help governments 
        and organizations be more efficient, effective and impactful. Read on to learn 
        more about the broad scope of our work and how we support our clients 
        to provide a better customer experience and, ultimately, improve the lives 
        of the people they serve.
        </p>
      </Section>
    )
  }

export default function CaseStudiesLanding() {
  return (
    <Layout id="case-studies-landing">
      <Seo
        title="Our Work"
        description="We work with government agencies and organizations that seek to drive real change and have the means to deliver on that promise."
        relPath={"/our-work/"}
      />
      <div className="page--case-studies">
        <SectionBanner
          id={"hero"}
          bgImage={"case-studies/landing-hero.jpg"}
          bgTextShade={true}
          title="Our Work"
        />
        <HeadingIntro title={"What We Do."} />
        <CaseStudies />
      </div>
    </Layout>
  )
}

